<template>
  <div style="text-align: center; background-color: white">
    <van-image class="" :src="require('@/assets/common/payment_complated.png')" width="30%" height="20%" />
    <div id="close" v-show="show">
      <p>本页面在{{ count }}秒后自动关闭</p>
      <button @click="closePage()">关闭</button>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "PaySuccess",
  data() {
    return {
      count: 0,
      timer: false,
      show: true,
      payType: 0,//0为空中云汇支付，1为stripe支付
    }

  },
  created() {
    this.clickJump();
  },
  methods: {

    closePage() {
      let url = "https://wx.playhy.com/#/";
      let url_type = this.$route.query.url_type;
      switch (url_type) {
        case "dajin":
          url += "dajin";
          break;
        case "icelord":
          url += "icelord";
          break;
        case "credit":
          url += "credit";
          break;
        case "darkxiyou":
          url += "darkxiyou";
          break;
        case "dscj":
          url += "dscj";
          break;
        case "egrxdld":
          url += "eg/rxdld";
          break;
        case "cnrxdld":
          url += "cn/rxdld";
          break;
        case "rxhj":
          url += "rxhj";
          break;
        case "secret":
          url += "secret";
          break;
        case "sgtl":
          url += "sgtl";
          break;
        case "smzb":
          url += "smzb";
          break;

        case "ghlcity":
          url += "ghlcity";
          break;
        case "fkjlb":
          url += "fkjlb";
          break;

      }
      window.location.href = url;
    },
    //几秒后进入跳转页面

    clickJump() {
      const timejump = 3;

      if (!this.timer) {
        this.count = timejump;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= timejump) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            //跳转的页面写在此处
            this.closePage();
          }

        }, 1000)

      }

    },


  },
  beforeDestroy() {    //页面关闭时清除定时器

    clearInterval(this.timer);
  },
}
</script>
  
<style scoped>
#close {
  font-size: 16px;
  text-align: center;
}
</style>
  